import {
  Box,
  Breadcrumbs,
  Button,
  CircularProgress,
  Container,
  CssBaseline,
  CssVarsProvider,
  Divider,
  Dropdown,
  Grid,
  IconButton,
  Input,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  Modal,
  ModalDialog,
  Skeleton,
  Stack,
  TextField,
  Typography,
} from "@mui/joy";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import constants from "../../configs/constants";
import CommonLayout from "../../layouts/CommonLayout";
import BigTitle from "../components/BigTitle";
import ActionButton from "../components/ActionButton";
import { getVideos, getVideosById } from "../../services/pexels";
import {
  RiArrowLeftLine,
  RiCloseCircleLine,
  RiDeleteBin3Fill,
  RiDraftLine,
  RiEdit2Fill,
  RiHome3Line,
  RiHomeLine,
  RiShareForwardLine,
} from "react-icons/ri";
import SectionTitle from "../components/SectionTitle";
import ShowMoreTextButton from "../components/ShowMoreTextButton";
import PaddingContainer from "../../layouts/PaddingContainer";
import VideoListCard from "../components/VideoListCard";
import { useNavigate, useParams } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";
import VodcastPlayer from "../components/VodcastPlayer/index.js";
import VideoToolsRow from "./VideoToolsRow";
import ProfileCard from "../components/ProfileCard";
import ReportVideoDialog from "../ReportDialogs/ReportVideoDialog";
import { getData } from "../../utils/httpMethods";
import CommentsBox from "../components/CommentsBox";
import axios from "axios";
import Swal from "sweetalert2";
import EditDialog from "./EditDialog";
import { BsThreeDotsVertical } from "react-icons/bs";
import { RWebShare } from "react-web-share";
import CommonViewLayout from "../../layouts/CommonViewLayout";
import { logout } from "../../Redux/slices/AuthSlice";

function VodcastOpenViewPage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { user } = useSelector((state) => state.auth);
  const { vodcast_id } = useParams();
  const [loading, setLoading] = useState(true);
  const [loadingOthers, setLoadingOthers] = useState(true);
  const [loadingOthersMore, setLoadingOthersMore] = useState(false);
  const [totalCount, setTotalCount] = useState(-1);
  const [data, setData] = useState(null);
  const [otherVideos, setOtherVideos] = useState([]);
  const [countData, setCountData] = useState(null);
  const [isShownMore, setIsShownMore] = useState(false);
  const [showReportDialog, setShowReportDialog] = useState(false);
  const [hasMore, setHasMore] = useState(false);

  const [postRating, setPostRating] = useState(data?.rating);
  const [profileRating, setProfileRating] = useState(data?.created_by?.rating);
  const [followState, setFollowState] = useState(data?.is_follow);
  const [showCommentSection, setShowCommentSection] = useState(false);
  const [comments, setComments] = useState([]);
  const [commentLoading, setCommentLoading] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [deleted, setDeleted] = useState(false);
  const [showEdit, setShowEdit] = useState(false);

  const [page, setPage] = useState(1);

  const [loadingMore, setLoadingMore] = useState(false);

  const isOwn =
    user?.user_type === "professional" &&
    localStorage.getItem("user_id") === data?.created_by?._id;

  useEffect(() => {
    setPostRating(data?.rating);
    setProfileRating(data?.created_by?.rating);
    setFollowState(data?.created_by?.is_followed);
  }, [data]);


  const loadMore = () => {
    !loading && !loadingOthersMore && !loadingOthers && setPage((p) => p + 1);
  };

  const fetchComments = useCallback(() => {
    setCommentLoading(true);
    getData(
      `${process.env.REACT_APP_BASE_URL}/vodcast/get-vodcast-comment/${vodcast_id}?offset=0`,
      "GET"
    )
      .then(({ data, status }) => {
        if (status === true) {
          setCommentLoading(false);
          setComments(data);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }, [vodcast_id]);

  useEffect(() => {
    fetchComments();
  }, []);

  useEffect(() => {
    if (vodcast_id) {
      setLoadingOthers(true);
      setLoading(true);
      getData(
        `${process.env.REACT_APP_BASE_URL}/vodcast/get-vodcast/${vodcast_id}`,
        "GET"
      ).then((res) => {
        setLoading(false);
        if (res.status == true) {
          if (res.data?.data?.length > 0) {
            setData(res.data?.data?.[0]);
          } else {
            setDeleted(true);
          }
          // setCountData(res.data?.count);
        } else {
        }
      });
    }
  }, [vodcast_id]);

  const fetchData = useCallback(() => {
    if (data?.created_by) {
      page === 1 && setLoadingOthers(true);
      page > 1 && setLoadingOthersMore(true);
      getData(
        `${process.env.REACT_APP_BASE_URL}/vodcast/get-vodcast-by-the-user/${data?.created_by?._id
        }?offset=${(page - 1) * 10}`,
        "GET"
      ).then((res) => {
        if (res.status == true) {
          const videos = res?.data?.filter((i) => i._id !== vodcast_id);

          setOtherVideos((d) => ({ ...d, [page]: videos }));
          setTotalCount(res.total_count);
        } else {
          console.log("incorrect");
        }

        setLoadingOthers(false);
        setLoadingOthersMore(true);
      });
    }
  }, [data, page]);

  useEffect(() => {
    fetchData();
  }, [data]);

  const loginVodcast = () => {

    Swal.fire({
      title: "Are sure you wanna report the vodcast",
      text: "Login to your account for reporting this video!",
      icon: "warning",
      background: "#232323",
      color: "#fff",
      // toast: true,
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sign In",
    }).then((result) => {

      if (result.isConfirmed) {

        dispatch(logout());
        navigate("/login", { replace: true });
        /* setDeleting(true);
        getData(
          `${process.env.REACT_APP_BASE_URL}/vodcast/delete/${data?._id}`,
          "DELETE"
        )
          .then((res) => {
            setDeleting(false);
            if (res.code === 200) {
              setDeleted(true);
              setData(null);
            } else {
              Swal.fire({
                title: "Failed",
                text: "Something went wrong",
              });
            }
          })
          .catch((e) => {
            setDeleting(false);
            Swal.fire({
              title: "Failed",
              text: "Something went wrong",
            });
          }); */
      }
    });
  };

  const onDeleteVodcast = () => {
    if (!isOwn) {
      return;
    }
    Swal.fire({
      title: "Are you sure to delete the vodcast?",
      text: "You won't be able to revert this!",
      icon: "warning",
      background: "#232323",
      color: "#fff",
      // toast: true,
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        setDeleting(true);
        getData(
          `${process.env.REACT_APP_BASE_URL}/vodcast/delete/${data?._id}`,
          "DELETE"
        )
          .then((res) => {
            setDeleting(false);
            if (res.code === 200) {
              setDeleted(true);
              setData(null);
            } else {
              Swal.fire({
                title: "Failed",
                text: "Something went wrong",
              });
            }
          })
          .catch((e) => {
            setDeleting(false);
            Swal.fire({
              title: "Failed",
              text: "Something went wrong",
            });
          });
      }
    });
  };

  return (
    <CommonViewLayout>
      {showReportDialog && (
        <ReportVideoDialog
          user={data?.created_by}
          onClose={() => setShowReportDialog(false)}
          vodcastId={data?._id}
        />
      )}
      {showEdit && (
        <EditDialog
          onClose={() => setShowEdit(false)}
          data={data}
          onSuccess={(res) => {
            setData({
              ...res,
              captions: res?.caption,
              rated_count: data?.rated_count,
              is_rated: data?.is_rated
            });
          }}
        />
      )}
      <Modal open={deleting}>
        <ModalDialog>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
            }}
          >
            <CircularProgress />
            <Typography>Removing Vodcast ...</Typography>
          </Box>
        </ModalDialog>
      </Modal>

      <InfiniteScroll
        // dataLength={
        //   (videosData && Object.values(videosData)?.flat()?.length) || 0
        // }
        dataLength={Object.values(otherVideos)?.flat().length}
        next={loadMore}
        // style={{ display: 'flex', flexDirection: 'column-reverse' }} //To put endMessage and loader to the top.
        // inverse={true} //
        hasMore={!loadingMore}
        // loader={<h4>Loading...</h4>}
        scrollableTarget="scrollableDiv"
      >
        <PaddingContainer>
          <Grid xs={6} sm={6} md={6} lg={8}>
            <Stack direction="row">
              {/* <IconButton
                onClick={() => navigate("/vodcast")}
                sx={{ ":hover": { background: "transparent" } }}
              >
                <RiArrowLeftLine size={30} />
              </IconButton> */}
              <Breadcrumbs>
                {/* {[{ label: "Home", link: "/vodcast" }].map((item) => (
                  <Link
                    key={item.label}
                    fontSize={{ xs: 10, sm: 12, md: 14, lg: 16 }}
                    color="neutral"
                    href={item.link}
                    referrerPolicy="no-referrer"
                    target="_self"
                  >
                    {item.label}
                  </Link>
                ))} */}
                {data && (
                  <Typography fontSize={{ xs: 10, sm: 12, md: 14, lg: 16 }}>
                    {data?.title}
                  </Typography>
                )}
              </Breadcrumbs>
            </Stack>
          </Grid>
        </PaddingContainer>
        {!deleted ? (
          <PaddingContainer>
            <Grid container sx={{ display: "flex", justifyContent: "center" }}>
              <Grid xs={12} md={7}>
                <VodcastPlayer shimmer={loading} data={data} />

                <VideoToolsRow
                  onDeleteVodcast={loginVodcast}
                  setProfileRating={loginVodcast}
                  setPostRating={loginVodcast}
                  postRating={postRating}
                  data={data}
                  loading={loading}
                  showCommentSection={showCommentSection}
                  setShowCommentSection={setShowCommentSection}
                  onReportClick={loginVodcast}
                  commentCount={
                    comments?.length +
                    comments.map((i) => i.replyComment).flat().length
                  }
                  onEditVodcast={() => setShowEdit(true)}
                />
                <Stack
                  direction="row"
                  sx={{
                    justifyContent: "flex-end",
                    alignItems: "center",
                    gap: 1.5,
                    display: { xs: "flex", sm: "none" },
                  }}
                >
                  <Skeleton variant="circular" loading={loading}>
                    <RWebShare
                      data={{
                        text: "Musicbook",
                        url:
                          "https://musicbook.co.in/vodcast/player/" + data?._id,
                        title: "Musicbook",
                      }}
                      onClick={() => console.log("shared successfully!")}
                    >
                      <IconButton
                        variant="solid"
                        sx={{
                          minWidth: 30,
                          minHeight: 30,
                          width: 30,
                          aspectRatio: 1,
                          borderRadius: 15,
                          backgroundColor: "#F5F5F512",
                        }}
                      >
                        <RiShareForwardLine size={14} />
                      </IconButton>
                    </RWebShare>
                  </Skeleton>
                  {!isOwn && (
                    <Skeleton variant="circular" loading={loading}>
                      <IconButton
                        variant="solid"
                        onClick={() => setShowReportDialog(true)}
                        sx={{
                          minWidth: 30,
                          minHeight: 30,
                          width: 30,
                          aspectRatio: 1,
                          borderRadius: 15,
                          backgroundColor: "#F5F5F512",
                        }}
                      >
                        <RiDraftLine size={14} />
                      </IconButton>
                    </Skeleton>
                  )}
                  {isOwn && (
                    <Skeleton variant="circular" loading={loading}>
                      <Dropdown>
                        <MenuButton
                          slots={{ root: IconButton }}
                          sx={{
                            minWidth: 30,
                            minHeight: 30,
                            width: 30,
                            aspectRatio: 1,
                            borderRadius: 15,
                            backgroundColor: "#F5F5F512",
                          }}
                          slotProps={{
                            root: { variant: "outlined", color: "neutral" },
                          }}
                        >
                          <BsThreeDotsVertical size={14} />
                        </MenuButton>
                        <Menu>
                          <MenuItem onClick={() => setShowEdit(true)}>
                            <RiEdit2Fill size={14} /> Edit Details
                          </MenuItem>
                          <MenuItem
                            color="danger"
                            onClick={() => setShowReportDialog(true)}
                          >
                            <RiDeleteBin3Fill size={14} /> Delete Vodcast
                          </MenuItem>
                        </Menu>
                      </Dropdown>
                    </Skeleton>
                  )}
                </Stack>

                {showCommentSection && (
                  <Grid sx={{ paddingBottom: 5, position: "relative" }}>
                    <CommentsBox
                      currentUser={data?.created_by}
                      comments={comments}
                      onUpdate={fetchComments}
                      loading={commentLoading}
                      vodcastId={vodcast_id}
                      setComments={setComments}
                    />
                  </Grid>
                )}
                <Typography fontSize={{ xs: 16, sm: 20, md: 20, lg: 24 }}>
                  {data?.title}
                </Typography>
                <ProfileCard
                  data={data?.created_by}
                  profileRating={profileRating}
                  postRating={postRating}
                  followState={followState}
                  setFollowState={setFollowState}
                  loading={loading}
                />
                {data?.credits && (
                  <Typography fontSize={{ xs: 12, sm: 12, md: 14, lg: 14 }}>
                    {`\nMusic Credits: ${data?.credits}`}
                  </Typography>
                )}
                <Typography
                  fontSize={{ xs: 12, sm: 12, md: 14, lg: 14 }}
                  sx={
                    !isShownMore
                      ? {
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        display: "-webkit-box",
                        WebkitLineClamp: "2",
                        WebkitBoxOrient: "vertical",
                        whiteSpace: "pre-wrap",
                      }
                      : {
                        whiteSpace: "pre-wrap",
                      }
                  }
                >
                  {data?.captions}{" "}
                </Typography>

                {(data?.captions.length > 100 ||
                  data?.captions.split(/\r\n|\r|\n/).length > 1) && (
                    <Button
                      onClick={() => setIsShownMore((s) => !s)}
                      variant="plain"
                      sx={{
                        py: 0,
                        px: 0,
                        minHeight: 0,
                        my: 1,
                        color: "#FFFFFF",
                        ":hover": {
                          color: "yellow",
                          background: "transparent",
                        },
                      }}
                    >
                      {isShownMore ? "Show Less" : "Read More"}
                    </Button>
                  )}
              </Grid>
            </Grid>
          </PaddingContainer>
        ) : (
          <PaddingContainer>
            <Box
              sx={{
                flex: 1,
                minHeight: "50vh",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",

                gap: 3,
              }}
            >
              <RiCloseCircleLine size={60} style={{ opacity: 0.6 }} />
              <Typography variant="soft" level="title-md" sx={{ opacity: 0.6 }}>
                Vodcast is Unavailable !
              </Typography>
              <ActionButton
                onClick={() => navigate("/vodcast")}
                title="Go to Home"
              />
            </Box>
          </PaddingContainer>
        )}
        <Container
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            mt: 6,
          }}
        >
          <Divider sx={{ width: "30%", height: 2, color: "#555555" }} />
        </Container>
        {!isOwn && otherVideos?.length !== 0 && (
          <SectionTitle
            title="User Also Likes"
            headerRight={
              <ShowMoreTextButton onClick={() => navigate("/vodcast")} />
            }
          />
        )}
        {!isOwn && otherVideos?.length !== 0 && (
          <PaddingContainer>
            <Grid container spacing={2}>
              {loadingOthers &&
                [1, 2, 3, 4, 5, 6, 7, 8].map((item, index) => (
                  <VideoListCard shimmer={loading} data={item} key={index} />
                ))}
              {!loadingOthers &&
                otherVideos &&
                Object.values(otherVideos)
                  .flat()
                  ?.map((item, index) => (
                    <VideoListCard data={item} key={index} />
                  ))}
            </Grid>
          </PaddingContainer>
        )}
        {isOwn && otherVideos?.length !== 0 && (
          <Container
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              py: 4,
            }}
          >
            {loadingMore ? (
              <CircularProgress variant="soft" />
            ) : hasMore ? (
              <Typography level="body-sm" sx={{ color: "#FFFFFF4A" }}>
                END OF CONTENT
              </Typography>
            ) : (
              !loading &&
              !loadingOthers &&
              !loadingOthersMore &&
              totalCount === 0 && (
                <Typography level="body-sm" sx={{ color: "#FFFFFF4A" }}>
                  No more data available
                </Typography>
              )
            )}
          </Container>
        )}
      </InfiniteScroll>
    </CommonViewLayout>
  );
}

export default VodcastOpenViewPage;
