import React, { useEffect } from "react";

const AdsComponent = ({ dataAdSlot }) => {
  useEffect(() => {
    if (typeof window !== "undefined") {
      try {
        (window.adsbygoogle = window.adsbygoogle || []).push({});
      } catch (e) {
        console.error("AdSense error: ", e);
      }
    }
  }, []);

  return (
    <ins
      className="adsbygoogle"
      style={{ display: "block", height: "80px" }}
      data-ad-client="ca-pub-2007858501574248"
      data-ad-slot={dataAdSlot}
      data-ad-format="auto"
      data-full-width-responsive="true"
    ></ins>
  );
};

export default AdsComponent;

// import React, { useEffect } from "react";

// const AdsComponent = (props) => {
//   const { dataAdSlot } = props;

//   useEffect(() => {
//     try {
//       (window.adsbygoogle = window.adsbygoogle || []).push({});
//     } catch (e) {}
//   }, []);

//   return (
//     <>
//       <ins
//         className="adsbygoogle"
//         style={{ display: "block", height: "80px" }}
//         data-ad-client="ca-pub-2007858501574248"
//         data-ad-slot={dataAdSlot}
//         data-ad-format="auto"
//         data-full-width-responsive="true"
//       ></ins>
//     </>
//   );
// };

// export default AdsComponent;
